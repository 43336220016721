<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">处理维修单</span>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label><b>单号:</b></label> {{ model.SN }}
          </div>
          <div class="form-group">
            <label><b>分类:</b></label> {{ model.CAT_NAME }}
          </div>
          <div class="form-group">
            <label><b>位置:</b></label> {{ model.LOC_NAME }}
          </div>
          <div class="form-group">
            <label><b>问题描述</b></label>
            {{ model.DESCRIPTION }}
          </div>
          <div class="form-group" v-if="model.ImageKvs">
            <el-image style="max-width: 300px;" v-for="item in model.ImageKvs" :src="item.Value" :key="item.Key"
              :preview-src-list="[item.Value]">
            </el-image>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label><b>维修结果</b></label>
            <el-input type="textarea" :rows="2" v-model="model.MEM" />
          </div>
          <div class="form-group">
            <label><b>维修费用</b></label><br />
            <el-input-number v-model="model.COST" />
          </div>
          <div class="form-group">
            <label><b>照片上传</b> <span style="color: red">(注：仅支持单张图片上传)</span></label>
            <el-upload accept="image/*" action="" :limit="1" list-type="picture-card" :auto-upload="false"
              :on-change="onChange" :file-list="fileList" :on-remove="() => { model.PIC_B64 = '' }">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label><b>签名</b></label>
            <sign-canvas v-if="!signUrl" class="sign-canvas" ref="SignCanvas" />
            <img v-if="signUrl" class="signImg" :src="signUrl" alt="">
            <div class="btns">
              <el-button type="danger" size="small" @click="canvasClear()" style="margin-right: 20px;">清空</el-button>
              <el-button type="primary" size="small" @click="saveAsImg()">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="loading">
        <el-button icon="el-icon-close" @click="show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit()">完成维修</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        model: {},
        fileList: [],
        signUrl: '',
        loading: false
      }
    },
    methods: {
      init(model) {
        this.show = true;
        this.model = JSON.parse((JSON.stringify(model)));
        this.fileList = []
        this.getSign()
      },
      submit() {
        let self = this;
        if (this.signUrl === '') {
          this.whale.toast.err("请提交签名")
          return
        }
        self.loading = true;
        this.whale.remote.getResult({
          url: "/api/School/FIX/NoteApi/Repaired",
          data: self.model,
          // data: { ID: 0 },
          finally() { self.loading = false; },
          completed: function () {
            self.loading = false;
            self.show = false;
            self.$emit("on-saved");
          }
        })
      },
      onChange(file) {
        let self = this;
        this.whale.image.compress({
          file: file.raw,
          callback(cf) {
            self.whale.image.toB64({
              file: cf,
              callback(b64) {
                self.model.PIC_B64 = b64;
              }
            })
          }
        })
      },
      // 
      canvasClear() {
        if (this.signUrl) {
          this.signUrl = ''
        } else {
          this.$refs.SignCanvas.canvasClear();
        }
      },
      // 保存图片
      saveAsImg() {
        const img = this.$refs.SignCanvas.saveAsImg();
        console.log(img)
        if (img) {
          const parts = img.split(";base64,");
          console.log("parts", parts);
          const contentType = parts[0].split(":")[1];
          const raw = window.atob(parts[1]);
          console.log("raw", raw);
          const rawLength = raw.length;
          const uInt8Array = new Uint8Array(rawLength);

          // 将 Base64 转换为 Uint8Array
          for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
          }

          // 创建 Blob 对象
          let blob = new Blob([uInt8Array], { type: contentType });
          console.log(blob);
          this.H5SignUploadPost(blob);
        } else {
          this.$message.warning('没有签名')
        }
      },
      H5SignUploadPost(blob) {
        const formData = new FormData();
        formData.append("file", blob, "sign.png");
        let self = this
        this.whale.remote.uploadSign({
          url: 'api/School/FIX/NoteApi/SignPcUpLoad',
          data: formData,
          completed: function (resp) {
            console.log('sign', resp.Data.DATA.OSS_URL)
            self.signUrl = resp.Data.DATA.OSS_URL
            self.model.MENDER_SIGN_PIC_NAME = resp.Data.NAME
          }
        })
      },
      getSign() {
        let self = this
        self.whale.remote.getResult({
          url: 'api/School/FIX/NoteApi/GetSignPc',
          completed(res) {
            self.signUrl = res.OSS_URL
            self.model.MENDER_SIGN_PIC_NAME = res.NAME
          }
        })
      },
    }
  }
</script>

<style>
  .sign-canvas {
    display: block;
    /* margin: 0 auto; */
    width: 300px;
    height: 120px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>