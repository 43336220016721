<template>
    <el-dialog :visible.sync="sta.show" >
        <span slot="title">详情</span>
        <div class="row"  id="print">
            <div class="col-md-6">
                <label><b>单号:</b></label> {{ data.SN }}
            </div>
            <div class="col-md-6">
                <label><b>分类:</b></label> {{ data.CAT_NAME }}
            </div>
            <div class="col-md-12">
                <label><b>描述:</b></label> {{ data.DESCRIPTION }}
            </div>
            <div class="col-md-12">
                <label><b>照片:</b></label>
                <el-image v-for=" img in data.ImageKvs" style="width: 100px;" :key="img.Key" :src="img.Value" />
            </div>
            <div class="col-md-12">
                <label><b>维修位置:</b></label> {{ data.LOC_NAME }}
            </div>
            <div class="col-md-12">
                <label><b>提交人:</b></label> {{ data.SUB_NAME }}
            </div>
            <div class="col-md-12">
                <label><b>提交时间:</b></label> {{ data.CREATED_DT | datetime('YYYY-MM-DD HH:mm:ss') }}
            </div>

            <template v-if="data.OUTSIDERS">
                <div class="col-md-12">
                    <label><b>服务单位:</b></label> {{ data.OUTSIDERS.MEM }}
                </div>
                <div class="col-md-6">
                    <label><b>维修人:</b></label> {{ data.OUTSIDERS.NAME }}
                </div>
                <div class="col-md-6">
                    <label><b>联系号码:</b></label> {{ data.OUTSIDERS.MOBILE }}
                </div>
            </template>

            <div class="col-md-12">
                <label><b>状 态:</b></label> {{ data.STA_TXT }}
            </div>
            <div class="col-md-12">
                <label><b>费 用:</b></label> {{ data.COST }}
            </div>
            <div class="col-md-12">
                <label><b>分配时间:</b></label> {{ data.ALLOT_DT | datetime('YYYY-MM-DD HH:mm:ss') }}
            </div>
            <div class="col-md-6">
                <label><b>维修时间:</b></label> {{ data.FINISH_DT | datetime('YYYY-MM-DD HH:mm:ss') }}
            </div>
            <div class="col-md-6">
                <label><b>维修完成照片:</b></label>
                <el-image v-if="data.PIC_URL_FINISH" style="width: 100px; " :src="data.PIC_URL_FINISH" />
            </div>
            <div class="col-md-12">
                <label><b>验收时间:</b></label> {{ data.CHECK_DT | datetime('YYYY-MM-DD HH:mm:ss') }}
            </div>
        </div>
        <div slot="footer" v-loading="sta.loading">
            <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
            <el-button icon="el-icon-folder" type="primary" @click="exportWx">导出</el-button>
            <el-button icon="el-icon-printer" type="success" v-print="printObj">打印</el-button>
        </div>
    </el-dialog>
</template>

<script>
    import jsFileDownload from 'js-file-download'
    export default {
        name: "index",
        data() {
            return {
                sta: {
                    show: false
                },
                data: {},
                printObj: {
                    id: 'print',
                }
            }
        },
        methods: {
            init(item) {
                this.sta = { show: true, loading: false }
                this.data = item
            },
            exportWx() {
                let self = this;
                this.sta.loading = true;
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.whale.remote.getExport({
                    url: "/api/School/FIX/Note/ExportWx",
                    data: formData,
                    completed: function (its) {
                        let name = "维修管理维修记录_" + self.whale.getGuid() + ".xlsx"
                        jsFileDownload(its, name)
                        self.sta.loading = false;
                    }
                })
            },
        },
    }
</script>

<style media="print">
    @media print {
        @page {
            size: landscape
        }
    }
</style>